import Keycloak from 'keycloak-js';
import env from "react-dotenv";

let initOptions = {
    url: env.KEYCLOAK_URL,
    realm: env.KEYCLOAK_RELAM,
    clientId: env.KEYCLOAK_CLIENT_ID,
    onLoad: 'check-sso',
    KeycloakResponseType: 'code',
}

const kc = new Keycloak(initOptions);

const initKeycloak = (onAuthenticatedCallback) => {
    kc.init({
        onLoad: initOptions.onLoad,
      }).then((auth) => {
        if (!auth) {
            kc.login();
          } else {
            onAuthenticatedCallback();
          }
      }, () => {
        console.log("Authentication Failure");
        onAuthenticatedCallback();
      });
  };

const doLogin = kc.login;

const doLogout = kc.logout;

const getToken = () => kc.token;

const getUserInfo = (userProperty) => kc.tokenParsed?.[userProperty] || null;

const getIsAuthenticated = () => kc.authenticated;


const keycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    getToken,
    getUserInfo,
    getIsAuthenticated
}

export default keycloakService;
