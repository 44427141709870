import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import styles from '../../styles/footer.module.scss';

const Footer = forwardRef((props, ref) => {
  const socialList = [
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/linkedin.svg`,
      link: 'https://www.linkedin.com/company/groupampersand/',
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/facebook.svg`,
      link: 'https://www.facebook.com/groupampersand/',
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/instagram.svg`,
      link: 'https://www.instagram.com/group_ampersand/',
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/twitter.svg`,
      link: 'https://twitter.com/group_ampersand',
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/youtube.svg`,
      link: 'https://www.youtube.com/@AmpersandGroup2017',
    },
  ];
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="bg-white" ref={ref}>
        <Container>
          <div className="py-2 py-md-3 py-lg-4 border-bottom border-light text-center">
            <span className="d-block fs-7 fw-semibold text-primary mb-2">
              Contact Support
            </span>
            <p className="fw-semibold">
              Vector Link: <a href="https://vector.vibgyorhigh.com/" target="_blank" style={{color: '#000000'}}>https://vector.vibgyorhigh.com</a> | +91 600 3000 700
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 py-3 py-md-4 text-center text-md-start">
            <p className="text-gray fs-8 mb-0">
            © {currentYear} Ampersand Group. All Rights Reserved.
            </p>
            <div className="d-flex align-items-center gap-3">
              <span className="text-gray fs-8">Follow us on:</span>
              <ul className={`${styles.amp_social_list}`}>
                {socialList?.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link} target="_blank">
                      <ReactSVG src={item.icon} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
});

export default Footer;
