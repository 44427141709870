import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../../styles/header.module.scss';
import keycloakService from "../../modules";
import { ReactSVG } from 'react-svg';

const Header = forwardRef((props, ref) => {
  return (
    <>
      <header className="py-2 py-md-3" ref={ref}>
        <Container className="d-flex align-items-center justify-content-between gap-3">
          <Link to={'/'}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="Logo" className={styles.amp_logo} />
          </Link>
          <div className='d-flex align-items-center justify-content-end gap-2 gap-md-3 mb-4 mb-md-5'>
            <span className='text-primary fw-light fs-6'>Hi, <strong className='fw-medium'>{keycloakService.getUserInfo('given_name')}</strong> </span>

            <OverlayTrigger placement='bottom-end' overlay={<Tooltip>Sign out</Tooltip>}>
              <Button onClick={() => keycloakService.doLogout()} type='button' variant='primary' className='amp_logout_btn'>
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/logout.svg`} />
              </Button>
            </OverlayTrigger>
          </div>
        </Container>
      </header>
    </>
  );
});

export default Header;
