import React from 'react';
import styles from '../../styles/loader.module.scss';
import { ReactSVG } from 'react-svg';

const Loader = () => {
  return (
    <>
      <div className={`${styles.amp_loader} d-flex align-items-center justify-content-center`}>
        <div className={styles.amp_loader_image}>
          <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loader.svg`} />
        </div>
      </div>
    </>
  );
};

export default Loader;
