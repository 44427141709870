module.exports = [
//   {
//     logo: `${process.env.PUBLIC_URL}/assets/images/logos/people_strong.svg`,
//     linkUrl: "https://ampersand.uat.peoplestrong.com/",
//   },
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/oracle.svg`,
    linkUrl: "https://login-ewzj-dev1-saasfaprod1.fa.ocs.oraclecloud.com/",
  },
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/hubble_star.svg`,
    linkUrl: "https://smart-staff-selection-dev.web.app/#/vgos-internal",
  },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/student_kare.svg`,
  //   linkUrl: "/",
  // },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/elevate.svg`,
  //   linkUrl: "/",
  // },
];
