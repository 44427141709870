import AppRouter from "./AppRouter";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { useEffect, useRef, useState } from "react";

function App() {
  const [minHeight, setMinHeight] = useState(0);
  const headerRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    function handleResize() {
      if (headerRef.current && footerRef.current) {
        setMinHeight(window.innerHeight - (headerRef.current.offsetHeight + footerRef.current.offsetHeight));
      } else if (footerRef.current) {
        setMinHeight(window.innerHeight - footerRef.current.offsetHeight);
      } else {
        setMinHeight(window.innerHeight);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Header ref={headerRef} />
      <main style={{ minHeight: `${minHeight}px` }} className='position-relative'>
        <AppRouter />
      </main>
      <Footer ref={footerRef} />
    </>
  );
}

export default App;
