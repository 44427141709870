import { Container, Row, Col } from 'react-bootstrap';
import IconCard from '../../Components/IconCard';
import cardData from '../../modules/applicationLinks'

export default function Home() {
  return (
    <>
      <section className="py-5 py-md-6 py-lg-7">
        <Container>
          <Row className="row-gap-6 align-items-center">
            <Col xs={12} lg={5}>
              <div className='position-relative mx-auto mx-lg-0'>
                <img src={`${process.env.PUBLIC_URL}/assets/images/about_image.png`} alt="About" className='img-fluid' />
              </div>
            </Col>
            <Col xs={12} lg={7}>
              <Row className="justify-content-center row-gap-3 row-gap-md-4 row-gap-lg-5">
                {cardData?.map((item, index) => (
                  <Col xs={12} md={6} key={index}>
                    <IconCard cardData={item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
